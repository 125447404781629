@use 'variables' as v;


// -------------- reset     

html {
    box-sizing: border-box;
    font-size: 18px;
}
  
*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  // font-weight: normal;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

img {
  display: block;
  max-width: 100%;
}
 
ol, ul { 
  list-style: none;
}

// img {
//   // max-width: 100%;
//   // height: auto;
// }

a {
  &:link, &:visited {
    text-decoration: none;
    color: v.$primary;
  }

  &:hover {
    // color: red;
    color: v.$primary-darker;
    transition: all .2s ease-out;
    // color: shade( $primary, 20% );
  }
} 

::-moz-selection { /* Code for Firefox */
  color: darken(v.$primary, 10%);
  background:  darken(v.$body-color,5%);
}

::selection {
  color: darken(v.$primary, 10%);
  background: darken(v.$body-color,5%);
}

:root {
  font-size: 16px;
  --transition-speed: 1800ms;
  // font-family: acumin-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}

