@use 'variables' as *;

h1 {
    color: $primary-bright;
    font-size: 3.25rem;
    // font-weight: 300;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 200;
    font-style: normal;
    margin: 3.5rem 0 2rem 0;
    line-height: 3.55rem;

    @media (max-width: 720px){
       font-size: 2.8rem;
       line-height: 3.25rem;
  }
}

h1.display {
  // font-size: clamp(2rem,25vw,4rem);
}
  
h2 {
    font-size: 2rem;
    // font-weight: 300;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-style: normal;
    color: $body-dark;
    // color: #727c81;
    line-height: 2.2rem;
    margin: 2.5rem 0 2rem 0;
}

body {
  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400;
  font-style: normal;
  // line-height: 1.3rem;
}

p {
  // font-size: 18px;
  line-height: 1.5rem;
}

.key-label {
  font-size: 10px;
  text-transform: uppercase;
  color: #5e6976;
  letter-spacing: 1px;
  font-weight: 500;
} 