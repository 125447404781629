
//  COLOR

$primary: #2fabe0;
$primary-lighter: lighten($primary, 10%);
$primary-bright: lighten($primary, 30%);
$primary-darker: darken($primary, 15%);
$background: #1f2326;
// $background: #191b1b;
$background-lighter: lighten($background, 4%);
$background-darker: darken($background, 5%);
// $body-color:$primary-bright;
$body-color: #757f85;
$body-dark: darken($body-color, 5%);
// $body-dark: $primary-bright;
$body-lighter: lighten($body-color, 15%);
$body-bright: lighten($body-color, 30%);
// TYPE

// LAYOUT
$padding: 10px;
$max-width: 1345px;