
@use 'variables' as v;

body {
    // padding: .75rem;    
    // max-width: 56em;
    background-color: v.$background;
    color: v.$body-color;
    padding-top: 53px;
  }
  
  