
@use 'variables' as v;
@use 'mixins' as *;

.blur { filter: blur(10px);}
  
#blocker {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  // background: rgb(22, 31, 37)
  background: rgba(v.$background, 0.85);
  // display: none;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 250ms, opacity 250ms;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 250ms;
}

.sticky {
  position: fixed !important;
  z-index: 9999;
  background: rgba(255,255,255,.9);
  width: 100%;
}

#main-header.hide {
	top: -54px;
}
#main-header.bg {
  background: rgba(v.$background-lighter,.9);
  // background: rgb(31,35,38);
  // background: linear-gradient(0deg, rgba(31,35,38,0) 0%, rgba(31,35,38,1) 15%);
}

#main-header {
  
    position: fixed !important;
    top: 0;
    z-index: 9999;
    // background: rgba(255,255,255,.8);
    width: 100%;
    max-width: 1250px;
    // height: 53px;
    transition: all .3s ease;
    

    @include content-padding;

    #site-title a:hover img {
      opacity: .7;
      transition: opacity 250ms;
    }

    #menu-open {
      position: absolute;
      top: 30px;
      right: 35px;
      // z-index: 1010;
    }
  
    #menu-close {
      position: absolute;
      top: 30px;
      right: 35px;
      z-index: 1010;
    }
  
    nav {

      font-family: neue-haas-grotesk-display, sans-serif;
  
      &#menu {   
        // z-index:1005;
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 6px;
        padding: 20px 20px;
        background-color: v.$background-lighter;
        // visibility: hidden;
        // display: none;
        max-width: 500px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        // opacity: 0;
        // transition: visibility 0s linear 300ms, opacity 300ms;
        
        p {
          font-family: neue-haas-grotesk-text, sans-serif;
          font-size: .8rem;
        }

        .inner-grid {
          display: grid;
          grid-row-gap: 1em;
          grid-template-columns:
          /* 1 */ 1fr
          /* 2 */ 1fr;
          grid-template-rows:
          /* 4 */ 1fr
          /* 5 */ 2fr;
          // columns: 1fr 1fr;
        }
  
        #contact-info{
          float:left;
          width: 200px;
          height: 100%;
        }
  
        img {
          width: 80px;
          height: 80px;
          border-radius: 40px;
        }
  
        ul {
            font-size: 1.3rem;
            line-height: 1.8rem;
            @media (min-width: 600px){
  
            }

            li {
              margin-bottom: .2rem;
            }
        }
  
        .fullwidth {
            @media (min-width: 600px){
            }
        }
      }
  
      h3 {
        font-size: 16px;
        // color: #a1a5a8;
      }
    }
    p#site-description {
      font-size: 1rem;
      color: darken(v.$body-color, 20%);
    }
  }