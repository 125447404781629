@use 'mixins';
@use 'variables' as *;

#content-container {
    @include mixins.content-padding;
}
#footer-container {
    @include mixins.content-padding;
}

#page-container {
    max-width: $max-width;
    margin: 0 auto;
}
// header

// #main-header {
//     position: relative;
//     z-index: 9999;
// }


// home page



.pusher {
    height: 100vw;
    height: 100vw;
    max-height: 100%;
    width: 100vw;
    position: relative;
    background: 0 0;
    // z-index: -1;
    // margin-top: -90px;
    pointer-events: none;
    opacity: 0;
    max-height: 100%;
}


#main-carousel {
    // z-index: -1;
    position: absolute;
    top:0;
    left:0;
    // height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: hidden;

    .carousel-cell {
        width: 100%; /* full width */
        height: 100vh; /* height of carousel */
    }

    .flickity-viewport {
        height: 100%;
        min-height: 100%;
        width: 100%;
    }

    .flickity-button {
        color: $primary;
        z-index: 1;
        // background: hsla(0, 0%, 100%, 0.45);
    }
    
    .flickity-prev-next-button {
        top: 50%;
        width: 20px;
        height: 35px;
        border-radius: 10%;
        transform: translateY(-50%);
    }
    
    img.hero {
        z-index: -10;
        height: 100vh;
        object-fit: cover;
        min-height: 100%;
        // min-width: 100vw;
        width: 100%;

        @media (max-width: 600px){
            min-height: 80%;
            top: 0;
            // transform: translateX(-50%) translateY(0%);
            // min-width: 100%;
        }
    }

    #projectInfo {
        position: absolute;
        bottom: 0px;
        left: 30px;
        backdrop-filter: blur(8px);
        width: 100%;
        margin: 0 -30px;
        padding: 12px 20px 45px 40px;
        border-top: 1px #a3a7ab dotted;

        h3 {
            color: rgb(40, 42, 43);
        }
        h2 {
            margin: 8px 0;
        }
    }
}


// projects index

body.page-projects {
    // background-color: #FFF;
    
    .projects-header {

        h1{
            margin: 0;
        }
        margin: 5rem 0 3rem 0;


    }

    nav>ul {
        list-style-type: none;
        padding-bottom: 10px;
        li {
            display: inline;
            margin: 0px 8px;
            margin:nth-child(1) 0;
        }
    }
}

// project index grid

.projects, .gallery-body {

    display: grid;
    // padding: 0 25px;
    width: 100%;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    
    @media (max-width: 720px){
        grid-template-columns: 1fr;
    }

    @media (min-width: 1200px){
        grid-template-columns: 1fr 1fr 1fr;
    }

    .hero {
        margin-bottom: 4px;
    }

    .project:hover img{
        opacity: .85;
        transition: opacity 300ms;
    }

    .project-info {
        height: 100px;
        
        h2 {
        margin: 10px 0 4px 0;
        font-size: 1.5rem;
        line-height: 1.9rem;
        
        a:hover {
            // text-decoration: underline;
            // border-bottom: 1px $primary solid
        }

    }
    }

    h1 {
        font-size: 2rem;
        margin: 12px 0;
        line-height: 2.25rem;
    }

    

    img {
        max-width: 100%;
    }

}

.photos {
    display: grid;
    // padding: 0 25px;
    width: 100%;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr auto;
    
    @media (max-width: 720px){
        grid-template-columns: 1fr;
    }

}

.col-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: end;
    align-items: end;
    column-gap: 1rem;
    max-width: 100%;
    @media (max-width: 720px){
        grid-template-columns: 1fr;
    }
}

.project-header, .photography-section-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-items: start;
    margin: 3rem 0;

    h1.display {
        margin: 0 0 3rem 0;
        padding: 0;
    }

    h2, h3 { 
        margin-top: 20px; 
    }
    h2 { 
        font-size: 1.5rem;
    }

    p {
        
        &:not(.projectSummary) {
            font-size: 12px;
            line-height: 1.15rem;
        }

        span.key-label {
            font-size: 10px;
            text-transform: uppercase;
            color: #5e6976;
            letter-spacing: 1px;
            font-weight: 500;
        } 

        &.projectSummary {
            padding-top: 1rem;
            font-size: 1rem;
            // padding-bottom: 3.5rem;
        }
    }

    @media (max-width: 720px){
        grid-template-columns: 1fr;
    }
}

// project entries navigation footer

#project-navigation{
    margin-left: -30px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    justify-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 50px;
    padding-top: 15px;
    background-color: $background-lighter;
    transition: all .3s ease;
    // text-align: center;
    .prev { display: inline; }
    .next { display: inline; margin-left: 20px; }
    .current-client{ }

    &.hide { bottom: -50px; }
    .bg { background: rgba($background-lighter,.9); }
}


// project entry

.project-body {

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
    // grid-template-rows: auto;

    .project-gallery {
        align-items: start;

        .caption {
            margin: .8rem 0 1.1rem 0;
        }
    }
    
    .project-highlight {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 6rem 0 3rem 0;
        // column-span: 2;
    
        h2 {
            font-weight: 600;
            color: $primary-bright;
            margin: 0;
            padding: 0;
            max-width: 85%;
        }
    
        @media (max-width: 720px){
            grid-template-columns: 1fr;
            h2 {
                margin: 1rem 0;
                padding: inherit;
            }
        }
    }
}


//photography index 

.photos .gallery-info h2 {
 margin: 8px 0;
}

.gallery-hero, .hero {
    margin-bottom: 25px;
}